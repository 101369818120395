import { ref, computed, watch, onMounted } from 'vue'
import router from '@/routes/routes.js';

export default function usePresentation() {
  const route = router.currentRoute;

  const modes = ['normal', 'fullscreen']
  const modeIndex = ref(0)
  const currentMode = computed(() => modes[modeIndex.value])

  const fullScreenElement = ref(null)
  const getRef = (elem) => {
    fullScreenElement.value = elem
  }

  const switchPresentationMode = () => {
    modeIndex.value = ++modeIndex.value % modes.length;
  }

  const _openFullscreen = (elem) => {
    if (!document.mozFullScreen && !document.webkitFullScreen) {
      if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else {
        elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else {
        document.webkitCancelFullScreen();
      }
    }
  }

  const _exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen();
    }
  }

  watch(currentMode, (newMode) => {
    if (newMode == 'fullscreen') {
      _openFullscreen(fullScreenElement.value)
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get('template_id');
      let id = `snap-`+myParam
      setTimeout(()=> {
        const template = document.getElementById(id).closest('.snap-start')
        //if(!template) return 
        let top = template.offsetTop
        const container = document.getElementById('full-screen-wrapper')
        container.scrollTo(0, top);
      }, 1000)
    }
    // if(newMode == 'normal') _exitFullscreen()
  })


  onMounted(() => {
    document.addEventListener("fullscreenchange", exitHandler, false);
    document.addEventListener("mozfullscreenchange", exitHandler, false);
    document.addEventListener("MSFullscreenChange", exitHandler, false);
    document.addEventListener("webkitfullscreenchange", exitHandler, false);
  })

  const exitHandler = (event) => {
    if (!document.fullscreenElement) {
      switchPresentationMode()
    }
  }


  return {
    currentMode,
    switchPresentationMode,
    getRef
  }
}