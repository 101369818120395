import store from '@/store';
import router from '@/routes/routes.js';
import { ref, computed, onMounted, set } from 'vue';
import { get, debounce } from 'lodash'
import { prevIndex, nextIndex  } from '@/plugins/lodash'

export const useCommunicator = () => {
    const route = router.currentRoute;
    const activeModuleIndex = ref(null);
    const activeLayoutIndex = ref(0);
    const activeTemplateIndex = ref(0);
    const modules = ref([]);
    const project = ref({});
    const communicator = ref({});

    const handleCommunicatorData = async () => {
        project.value = store.dispatch("project/getSolidObject", route.params.id);
        let res = await store.dispatch("communicator/getCommunicator", {
            communicator_id: route.params.communicator_id
        }).then(({data}) => data.data);

        modules.value = res.modules;
        communicator.value = res;
        _setQueryValues()
        loadInitialData()
    };

    const loadInitialData = () => {
        if(route.query.module_id) return
        if(modules.value.length) setActiveModule(0)
        _setQuery()
    } 

    const _setQuery = () => {
        // module_id=1&layout_id=50&template_id=285
        let query = {}
        if(activeLayout.value) query.layout_id = activeLayout.value.id
        if(activeModule.value) query.module_id = activeModule.value.id
        if(activeTemplate.value) query.template_id = activeTemplate.value.id

        router.push({name: 'communicator', params: route.params, query: query})
    }

    const activeModule = computed(() => modules.value[activeModuleIndex.value]);

    const isolatedModules = computed(() => {
        if(activeModuleIndex.value != null) {
            return [modules.value[activeModuleIndex.value]]
        }
        return modules.value
    })


    const activeLayout = computed(() => get(activeModule.value, `layouts[${activeLayoutIndex.value}]`));

    // const activeLayout = computed(() => tempActiveLayout);
    const activeTemplate = computed(() => get(activeLayout.value, `module_templates[${activeTemplateIndex.value}]`));

    const setActiveModule = (index = null) => {
        // should handle without index value
        if(activeModuleIndex.value != null) {
            activeModuleIndex.value = null
        } else {
            activeModuleIndex.value = index
        }
        _handleExpand(index)
    }

    const setActiveLayout = (index) =>  activeLayoutIndex.value = index;
    const setActiveTemplate = (index) => activeTemplateIndex.value = index;

    const setNextTemplate = () => {
        if(!activeLayout.value) return
        activeTemplateIndex.value = nextIndex(activeTemplateIndex.value, activeLayout.value.module_templates.length)
    }
    const setPrevTemplate = () => {
        if(!activeLayout.value) return
        activeTemplateIndex.value = prevIndex(activeTemplateIndex.value, activeLayout.value.module_templates.length)
    }


    const _handleExpand = (index) => {
        isolatedModules.value.forEach(module => set(module, 'expanded', false))
        if(activeModuleIndex.value != null) {
            isolatedModules.value[0].expanded = true
        }
    }

    const _setQueryValues = () => {
        if(route.query.module_id) setActiveModule(modules.value.findIndex(({id}) => id == route.query.module_id))
        if(route.query.layout_id && activeModule.value) {
            setActiveLayout(activeModule.value.layouts.findIndex(({id}) => id == route.query.layout_id))
            activeModule.value.layouts.forEach(item => set(item, 'expanded', false))
            set(activeLayout.value, 'expanded', true)
        }
        if(route.query.template_id && activeLayout.value) setActiveTemplate(activeLayout.value.module_templates.findIndex(({id}) => id == route.query.template_id))
    }

    const updateCommunicator = debounce((communicator) => {
        let formData = new FormData();
        formData.append('id', communicator.id)
        formData.append('title', communicator.title)
        store.dispatch("communicator/updateCommunicator", formData)
    }, 700)
    
    return {
        project,
        communicator,
        modules,
        isolatedModules,
        activeModule,
        activeLayout,
        activeTemplate,
        updateCommunicator,
        handleCommunicatorData,

        // Methods
        setActiveModule,
        setActiveLayout,
        setActiveTemplate,
        setNextTemplate,
        setPrevTemplate,
        loadInitialData
    }
}