import html2pdf from 'html2pdf.js';
import { merge } from 'lodash';
import { ref } from 'vue'
export const usePdf = (config) => {
    const element = ref(null);
    const PDFLoader = ref(false);
    let option = merge({
        margin:       0,
        filename:     'myfile.pdf',
        image:        { type: 'jpeg', quality: 0.98 },
        html2canvas:  {
            scale: 2,
            // width: 3508,
            // height: 2480
        },
        jsPDF:        { unit: 'px', format: 'a4', orientation: 'portrait' }
    }, config);
    const downloadPDF = async () => {
        PDFLoader.value = true;
        
        await html2pdf().set(option).from(element.value).save();

        PDFLoader.value = false;
    }

    const getRef = el => {
        element.value = el;
    }

    return {
        downloadPDF,
        PDFLoader,
        getPdfElement: getRef
    }
}