<template>
    <BaseLayout :sidebarVisibility="sidebarVisibility" @exitFullScreen="sidebarVisibility = true">
        <template #sidebar>
            <Sidebar 
                :switchSidebarComponent="switchSidebarComponent"
                :inactiveItems="['Add', 'Properties']"
            >
                <component
                    v-bind="sidebarProps"
                    :is="activeSidebarComponent"
                />
            </Sidebar>
        </template>
        <template #toolbar>
            <Toolbar 
                @onClick="switchPresentationMode"
                :activeTemplate="activeTemplate"
                :communicator="communicator"
                @updateCommunicator="updateCommunicator"
                :update-template="updateTemplate"
                :downloadPDF="downloadPDF"
                :PDFLoader="PDFLoader"
            />
        </template>
        <template v-if="currentMode == 'normal'">
            <div v-if="activeModule" class="flex gap-x-8">
                <TemplateLeftSidebar
                    v-if="activeTemplate"
                    :activeTemplate="activeTemplate"
                    :template-id="activeTemplate.id"
                    :layout-id="activeLayout.id"
                />
                <TemplateContainer
                    :module="activeModule"
                    :modules="modules"
                    :active-template="activeTemplate"
                    :active-layout="activeLayout"
                    :switchSidebarComponent="switchSidebarComponent"
                    :setNextTemplate="setNextTemplate"
                    :setPrevTemplate="setPrevTemplate"
                    :current-mode="currentMode"
                    :getPdfElement="getPdfElement"
                />
                <TemplateRightSidebar v-if="activeLayout" @addTemplate="createTemplate(activeLayout, activeTemplate.id)" />
            </div>
            <ModuleComponent :setActiveModule="setActiveModule" v-else :modules="modules" :layout="activeLayout"/>
        </template>

        <div :ref="getRef">
            <Presentation
                v-if="currentMode=='presentation' || currentMode=='fullscreen'"
                :current-mode="currentMode"
                :modules="modules"
                :module="activeModule"
                :layout="activeLayout"
            />
        </div>
        <!-- <FullScreen v-show="currentMode == 'fullscreen'" :current-mode="currentMode" :getRef="getRef" :module="activeModule"/> -->
    </BaseLayout>
</template>

<script setup>
    import { computed, ref, onMounted } from 'vue';
    import { useCommunicator } from '@/views/screens/communicator/useCommunicator';
    import { useSidebar } from '@/views/screens/communicator/useSidebar';
    import { useTemplate } from '@/views/screens/communicator/useTemplate.js';
    import { usePdf } from "@/composable/pdf/usePdf.js";
    import usePresentation from '@/views/screens/communicator/usePresentation';
    import store from '@/store';
    import router from '@/routes/routes.js';
    import { BaseLayout, Sidebar, Navbar, Toolbar, ModuleLayout, TemplateLeftSidebar, FullScreen, Presentation, TemplateContainer, TemplateRightSidebar, ModuleComponent } from '@/views/screens/communicator/useImporter.js';

    const { switchSidebarComponent, activeSidebarComponent, currentSidebar, propsData, sidebarVisibility } = useSidebar();
    const { handleCommunicatorData, project, modules, isolatedModules, activeModule, activeLayout, activeTemplate, setActiveModule, loadInitialData, setActiveLayout, setActiveTemplate, setNextTemplate, setPrevTemplate, communicator, updateCommunicator } = useCommunicator();
    const { currentMode, switchPresentationMode, getRef } = usePresentation();
    const { createTemplate, updateTemplate } = useTemplate();
    const pdfConfiguration = {
        filename: 'communicator-template.pdf',
        jsPDF: {
            orientation: 'landscape'
        }
    }
    const { downloadPDF, getPdfElement, PDFLoader } = usePdf(pdfConfiguration);

    const route = router.currentRoute;

    const sidebarProps = computed(() =>  {
        return {
            ...sidebarPropsDataSet.value[currentSidebar.value],
            ...propsData.value
        }
    });

    const sidebarPropsDataSet = ref({
        'ModuleList': {
            modules,
            activeTemplate,
            activeModule,
            isolatedModules,
            setActiveModule,
            setActiveLayout,
            setActiveTemplate,
            getPdfElement,
            loadInitialData
        },
        'TextSource': {
            modules,
            template: activeTemplate
        },
        'DataSource': {
            modules,
            activeModule,
            template: activeTemplate
        },
        'OkrDataSource': {
            modules,
            activeModule,
            template: activeTemplate
        },
        'OkrContentDataSource': {
            modules,
            activeModule,
            template: activeTemplate
        },
        'OkrManagementDataSource': {
            modules,
            activeModule,
            template: activeTemplate
        },
        'PeopleSelector': {
            template: activeTemplate
        },
        
        'HighlevelFilter': {
            template: activeTemplate
        }
        
    });

    onMounted(() => {
        handleCommunicatorData()
        store.dispatch('definition/getDefinitionSets', { project_id: route.params.id });
    })
</script>